import moment from 'moment'
import 'moment/locale/fr'
import React, { useEffect, useState } from 'react'
import { FiCheck, FiLoader, FiRefreshCw, FiTrash2, FiX } from 'react-icons/fi'
import Button from '../components/Button'
import Loader from '../components/Loader'

moment('fr')
/*
  https://cache.wizito.com/23a5b54b9e26f2fd46cebf56ceefd48f14f03a11/
  { name, ..., _date: Moment().format(), _link }
*/

function capitalize(string) {
  return (string.charAt(0).toUpperCase() + string.slice(1)).replace(/_/g, ' ')
}

const location = () => (typeof window !== `undefined`) ? window.location : { pathname: '' }
const cacheId = location().pathname.replace('/', '')

const sample = [
  {
    url: 'https://cache.wizito.com/83c3fa62bac16810ea247a3a3587415746c77452',
    cache: [
      { name: 'LIS1', last_value: '25', last_session_date: '2014-09-08T08:02:17-05:00' },
      { name: 'LIS2', last_value: '12', payments_link: 'https://', remote_link: 'https://' },
      { name: 'LIS3', last_value: '55' }
    ],
    error: null
  },
  {
    url: 'https://cache.wizito.com/802076dc9f4844778fe77bace7ce71fd6ab6c1cd',
    cache: [
      { name: 'CHEERZ1',
        last_session_date: '2021-04-22T14:18:32.335Z',
        last_payment_date: '2021-04-22T11:10:23.595Z' },
      { name: 'CHEERZ2',
        last_session_date: '2021-04-15T16:39:40.402Z',
        last_payment_date: '2021-04-15T16:40:00.217Z' }
    ],
    error: null
  }
]

export default function Home() {
  const [config, setConfig] = useState()
  const [datas, setDatas] = useState()
  useEffect(() => {
    fetch(`https://cache.wizito.com/${cacheId}`)
    .then(r => r.json())
    .then(setConfig)
  }, [])
  useEffect(() => {
    if (!config) return
    setInterval(fetchUrls, 10*60*60)
    fetchUrls()
  }, [config])

  const fetchUrls = () =>
    Promise.all(
      config.urls.map(url => fetch(url).then(r => r.json()).then(r => ({ cache: r, url })))
    ).then(d => setDatas(d))

  const refresh = url => {
    datas.find(d => d.url === url).loading = true
    setDatas([...datas])
    fetch(`${url}/refresh`).then(r => r.json()).then(r => {
      const data = datas.find(d => d.url === url)
      data.cache = r
      data.loading = false
      setDatas([...datas])
    })
  }
  
  if (!config || !datas) return <Loader />
  console.log(datas)
  return (
    <div className="p-10">
      <div className="text-4xl font-thin text-center mb-12">{config.name}</div>
      {datas.map(data =>
        <div key={data.url}>
          <div className="m-4 -mb-2 font-mono text-xs text-gray-300">
            {data.url}
            {data.loading
              ? <FiLoader className="inline-block ml-3 mb-1 rotating transform scale-150" />
              : <FiRefreshCw className="inline-block ml-3 mb-1 cursor-pointer" onClick={() => refresh(data.url)} />}
          </div>
          <div className={`flex flex-wrap ${data.loading && 'opacity-50'}`}>
            {data.cache.sort((a, b) => a.name.localeCompare(b.name)).map(c => 
              <div key={c.name} className="bg-white shadow px-4 py-2 m-4 radius w-80">
                <div className="text-center font-bold mb-3">{c.name}</div>
                {Object.keys(c).sort((a, b) => a.localeCompare(b)).filter(k => k !== 'name' && !k.match(/_link/)).map(k =>
                  <div key={k} className=" text-black font-thin">
                    <span className="text-gray-800 font-medium mr-2">{capitalize(k.replace('_date', ''))}</span>
                    <span title={moment(c[k]).format('MM/DD/YYYY HH:MM')}>{k.match(/_date/) && c[k] && moment(c[k]).fromNow()}</span>
                    {typeof c[k] === 'boolean' && (c[k] ? <FiCheck className="inline-block" /> : <FiX className="inline-block" />)}
                    {!k.match(/_date/) && c[k]}
                  </div>  
                )}
                <div className="flex justify-center mt-1">
                  {Object.keys(c).filter(k => k.match(/_link/)).map(k =>
                    <Button key={k} href={c[k]} variant="small" className="m-1">{capitalize(k.replace('_link', ''))}</Button>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>  
      )}
    </div>
  )
}
